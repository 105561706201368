import AppStore from "./appstore/AppStore";
import Features from "./features/Features";
import Hero from "./hero/Hero";
import Journey from "./journey/Journey";
import Security from "./security/Security";
import Value from "./value/Value";

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <Value />
      <Journey />
      <Security />
      <AppStore />
    </>
  );
};

export default Home;

import React from 'react';

import { Container, SectionWrapper, Row } from '../../../../helpers/GlobalStyle'

import Typography from '../../../atoms/Typography/Typography'
import bg from './../../../../assets/images/hero-lines.png'
import quran from "./../../../../assets/images/Rectangle 7.svg";

import { ValueWrapper } from './ValueStyle'

const Value = () => {
    return (
        <div>

            <ValueWrapper style={{ backgroundImage: `url(${bg})` }}>
                <Container>

                    <Row style={{ alignItems: "center" }}>
                        <div>
                            <Typography variant="h3" weight="semi-bold">
                            Smart saving and investment that aligns with your values
                            </Typography>
                            <Typography variant="p1">
                            Whether you seek conventional avenues or prefer Sharia-compliant alternatives, Habila has you covered. Explore our range of products today and start building your financial future with our automated features and smart technology.
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <img src={quran} alt="JSON Image" style={{ width: "100%", height: "350px" }} loading='lazy' />
                        </div>
                    </Row>
                </Container>
            </ValueWrapper>
        </div>
    )
}

export default Value;

import {
  Container,
  Row,
  SectionWrapper,
} from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import box from "./../../../../assets/images/treasure-box.png";
import phone from "./../../../../assets/images/homescreen.png";
import brownShadow from "./../../../../assets/images/hero-shadow.png";
import greenShadow from "./../../../../assets/images/greenshadow.png";

const Security = () => {
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <SectionWrapper>
        <Container>
          <Row style={{ alignItems: "center" }}>
            <div>
              <Typography variant="h3" weight="semi-bold">
                Your funds
                are secured with bank-level encryption
              </Typography>
              <Typography variant="p1">
                We keep your financial assets and money with a licensed custodian to prevent fraud,
                misappropriation, misuse, theft, and/or loss, and in accordance with applicable law
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <img src={box} style={{ width: "100%", maxWidth: "350px" }} loading="lazy" />
            </div>
          </Row>

          <Row style={{ alignItems: "center", marginTop: 150 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img src={phone} style={{ width: "100%", maxWidth: "350px" }} loading="lazy" />
            </div>
            <div style={{ paddingTop: 24 }}>
              <Typography variant="h3" weight="semi-bold">
                Safe haven
              </Typography>
              <Typography variant="p1">
                We collaborate with CBN and SEC-licensed partners to keep and
                manage your funds. Our Payment Partner is PCI-DSS compliant, so
                your credit card information is secure.
              </Typography>
            </div>
          </Row>
        </Container>
      </SectionWrapper>
      <img
        src={brownShadow}
        style={{
          position: "absolute",
          width: 800,
          height: 800,
          top: 0,
          right: 0,
          transform: "rotate(-90deg)",
          zIndex: -1,
        }}
        loading="lazy"
      />

      <img
        src={greenShadow}
        style={{
          position: "absolute",
          width: 600,
          height: 800,
          bottom: -200,
          left: 0,
          transform: "rotate(0deg)",
          zIndex: -1,
        }}
        loading="lazy"
      />
    </div>
  );
};

export default Security;

import styled from "styled-components";
import { Extra, Grey, Primary } from "../../../atoms/Colors";

export const FeatureWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  margin-top: 120px;
  @media (max-width: 768px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 480px) {
    grid-template-columns: auto;
  }
`;

export const FeatureCard = styled.div`
  padding: 20px;
  gap: 20px;
  border-radius: 30px;
  background: #fff;
  .text {
    margin: 32px 0px;
  }
  /* display: flex; */
  /* flex-direction: column; */
`;

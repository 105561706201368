import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { Grey, Primary, Dark } from "../components/atoms/Colors";

export const GlobalStyle = createGlobalStyle`
    body{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box !important;
        font-family: 'Outfit', sans-serif;
        background: #FAFAFB;
        font-size: 14px;
        /* color: ${Grey[500]}; */
        color: #0C0F0E;
    };

    a{
      text-decoration: none;
      color: ${Primary[500]}
    }

    .MuiTypography-root {
    font-family: "montserrat" !important;
  }
    h1,h2, h3,h4,h5,h6{
      margin: 0;
      padding: 0;
    }

    .MuiPaper-root {
      border-radius:20px !important;
    }
`;

export const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1440px) {
    margin: auto 88px;
  }

  @media (max-width: 1000px) {
    margin: auto 56px;
  }

  @media (max-width: 567px) {
    margin: auto 20px;
  }
`;

export const SectionWrapper = styled.div`
  padding: 112px 0px;
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  > div {
    flex: 1;
  }
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

export const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 920px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const GridColumn = styled.div`
  width: 560px;
  display: flex;
  flex-direction: column;
  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const Browse = styled.ul`
  color: ${Primary[500]};
  font-weight: 600;
  font-size: 14px;
  padding: 0px;
  text-decoration: none;
  list-style: none;
`;

// export default GlobalStyle;

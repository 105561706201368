import { Container, SectionWrapper } from "../../../../helpers/GlobalStyle";
import Typography from "../../../atoms/Typography/Typography";
import { JourneyWrapper, JourneyCard } from "./JourneyStyle";
import user from "./../../../../assets/images/icons/3duser.png";
import coins from "./../../../../assets/images/icons/coins.png";
import plant from "./../../../../assets/images/icons/save-plant.png";
import Button from "../../../atoms/Button/Button";

const Journey = () => {
  return (
    <div>
      <SectionWrapper>
        <Container>
          <div style={{ width: "100%", maxWidth: 450, margin: "auto" }}>
            <Typography variant="h3" weight="semi-bold" align="center">
            Start your journey to financial freedom:
            </Typography>
          </div>

          <JourneyWrapper>
            <div style={{display: "flex", alignItems: "center"}}>
              <JourneyCard bgColor="#edf3f8">
                <Typography variant="h4" weight="semi-bold">
                  01
                </Typography>
                <div>
                  <Typography variant="h4" weight="semi-bold">
                    Create an account
                  </Typography>
                  <Typography variant="p1">
                    Sign up with your name, email/phone number and your
                    preferred payment method
                  </Typography>
                </div>
                <img src={user} width={200} />
              </JourneyCard>
            </div>

            <div className="middle">
              <JourneyCard bgColor="#edeae5">
                <img src={coins} width={150} />

                <Typography variant="h4" weight="semi-bold" display="flex" textAlign="center">
                  02
                </Typography>
                <div>
                  <Typography variant="h4" weight="semi-bold">
                    Fund your account
                  </Typography>
                  <Typography variant="p1">
                  Using preferred payment method, credit your chosen Habila account
                  </Typography>
                </div>
              </JourneyCard>
            </div>
            <div>
              <JourneyCard bgColor="#edf7f4">
                <img src={plant} width={300} />
                <Typography variant="h4" weight="semi-bold">
                  03
                </Typography>

                <div>
                  <Typography variant="h4" weight="semi-bold">
                    Let your money grow
                  </Typography>
                  <Typography variant="p1">
                    Sit back, watch your money grow and investments flourish!
                  </Typography>
                </div>
              </JourneyCard>
            </div>
          </JourneyWrapper>
          <div style={{ marginTop: 20 }}>
            <Button label="Learn More" style={{ margin: "auto" }} />
          </div>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Journey;

import { Container, SectionWrapper } from '../../../../helpers/GlobalStyle'
import Button from '../../../atoms/Button/Button'
import Typography from '../../../atoms/Typography/Typography'
import { FeatureWrapper, FeatureCard } from './FeatureStyle'
import { Link, useNavigate } from 'react-router-dom'

const data = [
    {
        icon: '😎',
        title: 'Regular',
        desc: 'Save casually whenever you want, for emergencies or till you hit your goal',
        sub: '8% - 15% earnings p.a',
    },
    {
        icon: '🎯',
        title: 'Targeted',
        desc: 'Smash your savings goals with strict saving plans that keeps you in check',
        sub: '10% - 20% earnings p.a',
    },
    {
        icon: '$',
        title: 'Save in USD',
        desc: 'Save in foreign currency and withdraw directly to your domiciliary account',
        sub: '5% - 10% earnings p.a',
    },
    {
        icon: '🔐',
        title: 'Lock Funds',
        desc: 'Safely, lock your money away for a specified duration until maturity.',
        sub: '20% earnings p.a',
    },
]

const Features = () => {
    return (
        <div>
            <SectionWrapper>
                <Container>
                    <div style={{ width: '100%', maxWidth: 750, margin: 'auto' }}>
                        <Typography variant='h3' align='center'>
                        Get access to seamless savings and investment options in Naira and USD
                        </Typography>
                    </div>

                    <FeatureWrapper>
                        {data.map((val, i) => (
                            <FeatureCard key={i}>
                                <Typography variant='h4'>{val.icon}</Typography>
                                <div className='text'>
                                    <Typography variant='h5'>{val.title}</Typography>
                                    <Typography variant='p2'>{val.desc}</Typography>
                                </div>
                                <Typography variant='p3' style={{ marginBottom: 24 }}>
                                    {val.sub}
                                </Typography>
                                <a
                                    href='https://forms.gle/EjxnTqBK3LCciCUU8'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Button label='Join Waitlist' color='grey' />
                                </a>
                            </FeatureCard>
                        ))}
                    </FeatureWrapper>
                </Container>
            </SectionWrapper>
        </div>
    )
}

export default Features

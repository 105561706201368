import styled from "styled-components";
import { Grey, Primary, Dark } from "../../atoms/Colors";

export const Nav = styled.nav`
  position: relative;
  width: 100%;
  height: 100px;
  background: #fafafb;
  display: flex;
  align-items: center;
  z-index: 1;
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoMenu = styled.div`
  display: flex;
  gap: 32px;
`;

export const NavMenu = styled.div`
  margin: auto;
  > ul {
    padding: 0px;
  }
  > ul > li {
    display: inline;
    padding: 0 15px;
    font-weight: 400;
  }
  a {
    color: ${(props) => (props.mode === "dark" ? Dark.heading : Grey[700])};
    text-decoration: none;
    font-weight: 500;
  }
  a:hover {
    color: ${Primary[500]};
  }
  @media (max-width: 1200px) {
    > ul > li {
      padding: 10px;
    }
  }
  @media (max-width: 920px) {
    display: none;
  }
`;

export const NavBtnWrapper = styled.div`
  display: flex;
  @media (max-width: 920px) {
    display: none;
  }
`;

export const MobileMenuBtn = styled.div`
  display: none;
  > button {
    color: ${(props) => (props.mode === "light" ? Grey[700] : Grey[50])};
    padding: 0px;
  }
  @media (max-width: 920px) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #fff;
  position: fixed;
  top: 0px;
  color: ${Grey[700]};
`;

export const MobileContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

export const MobileMenu = styled.div`
  > ul {
    padding: 0px;
  }
  > ul > li {
    display: block;
    text-align: center;
    padding: 20px 0;
    font-weight: 600;
  }
  a {
    color: ${Grey[700]};
    text-decoration: none;
  }
`;

export const MobileCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NavIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    margin-right: 20px;
  }
`;

export const MenuDropdown = styled.span`
  button {
    background: none;
    border: 0px;
    font-family: "montserrat";
    font-weight: 600;
    color: ${(props) => (props.mode === "light" ? Grey[700] : Dark.heading)};
  }
  .group {
    box-shadow: none;
  }
  .popper {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    padding: 12px;
    background: ${(props) => (props.mode === "light" ? "#fff" : Dark.bgCard)};
  }

  .item {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 10px 20px;
    color: ${(props) => (props.mode === "light" ? Grey[700] : Dark.heading)};
  }
  .item:hover {
    background: ${(props) => (props.mode === "light" ? Grey[50] : Dark.bg)};
    border-radius: 10px;
  }
`;

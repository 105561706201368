import { Link } from "react-router-dom";
import { Container } from "../../../helpers/GlobalStyle";
import Button from "../../atoms/Button/Button";
import Typography from "../../atoms/Typography/Typography";
import img from "../../../assets/images/404.gif";

const NotFound = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "720px",
          height: "100vh",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container>
          <img src={img} width="100%" style={{ borderRadius: "30px" }} />
          <Typography variant="h1" align="center">
            404!
          </Typography>
          <Typography variant="p1" align="center">
            <strong>Oops!</strong> Seems you followed a wrong or broken link.
            click the button below to find your way back to the home page
          </Typography>

          <center>
            <Link to="/">
              <Button label="Home Page" />
            </Link>
          </center>
        </Container>
      </div>
    </>
  );
};

export default NotFound;

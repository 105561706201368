import styled from "styled-components";
import { Dark, Grey, Primary } from "../../atoms/Colors";

export const FooterWrapper = styled.div`
  padding: 120px 0px;
  background: #0c0f0e;
  .menu {
    display: flex;
    gap: 40px;
  }
  p {
    color: #adb2b1;
  }
  @media (max-width: 768px) {
    .menu {
      flex-direction: column;
      margin-top: 50px;
    }
  }
`;

export const FooterMenu = styled.div`
  color: ${Grey[400]};
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 60px;
  width: 80%;
  a {
    color: #adb2b1;
    text-transform: capitalize;
    &:hover {
      color: ${Primary[500]};
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: auto auto;
  }
`;

export const CopyRights = styled.div`
  text-align: center;
  padding: 20px 0px;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  p {
    color: #adb2b1;
    font-size: 12px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

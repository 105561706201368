import {
  FooterWrapper,
  FooterMenu,
  // SocialIconWrapper,
  // SocialIcon,
  CopyRights,
} from "./FooterStyle";
import { Container, Row } from "../../../helpers/GlobalStyle";
import logo from "../../../assets/images/logo-invert.png";
import Typography from "../../atoms/Typography/Typography";
import { Link } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row style={{ justifyContent: "space-between" }}>
          <div>
            <img src={logo} alt="Nerdy Eye Logo" width="100" />
            <Typography variant="p3">Your money’s best friend</Typography>
          </div>
          <div className="menu">
            <Typography
              variant="p1"
              style={{
                margin: 0,
                color: "#fff",
              }}
            >
              Follow us
            </Typography>
            <FooterMenu>
              <Link to="#">Threads</Link>

              <Link to="#">Twitter</Link>

              <Link to="#">Instagram</Link>

              <Link to="#">Linkedin</Link>

              <Link to="#">Youtube</Link>

              <Link to="#">Medium</Link>
            </FooterMenu>
          </div>
        </Row>

        <CopyRights>
          <Typography variant="p3">© 2024 | All Rights Reserved</Typography>
          <Typography variant="p3">
            This page uses cookies. See cookies details here
          </Typography>
        </CopyRights>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;

import { GlobalStyle } from "./helpers/GlobalStyle";
import AnimatedCursor from "react-animated-cursor";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <>
      <GlobalStyle />
      <AnimatedCursor
        outerSize={30}
        trailingSpeed={12}
        outerScale="2"
        color="0, 195, 120"
      />
      <RouterProvider router={router} />
    </>
  );
};

export default App;

import styled from "styled-components";
import { Extra, Grey, Primary } from "../../../atoms/Colors";


export const ValueWrapper = styled.div`
margin-right: 150px;
margin-left: 150px;  
padding: 50px;
background: #fff;
border-radius: 30px;

@media (max-width: 1440px) {
  margin-right: 90px;
  margin-left: 90px;
}

@media (max-width: 1000px) {
  margin-right: 50px;
  margin-left: 50px;
  padding: 20px 10px;
}

@media (max-width: 567px) {
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px 10px;
}

`;

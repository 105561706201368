import ErrorPage from "./../ErrorPage";
import Main from "../components/pages/Main";
import Home from "../components/templates/home/Home";

import NotFound from "../components/pages/error/NotFound";

const Index = [
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default Index;

import { useState } from "react";
import {
  Nav,
  NavBtnWrapper,
  MobileMenuBtn,
  MobileWrapper,
  MobileContentWrapper,
  MobileCTA,
  LogoMenu,
} from "./NavbarStyle";
import { Container } from "../../../helpers/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../../../assets/images/logo.png";
import Button from "../../atoms/Button/Button";
import FeatherIcon from "feather-icons-react";
import { Grey } from "../../atoms/Colors";

const Navbar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const navigate = useNavigate();

  const handleMobileClick = (path) => {
    navigate(path);
    setShowMobileNav(false);
  };

  return (
    <Nav>
      <Container>
        <LogoMenu>
          <Link to="/">
            <img src={logo} width="100" alt="Nerdy Eye Logo" />
          </Link>
        </LogoMenu>

        <NavBtnWrapper>
          <Link to="https://drive.google.com/file/d/1X5nIUlZ3fj8ViXwmwqXhIF49GKtzSaEX/view?usp=sharing">
            <Button
              label="Terms & Agreement"
              variant="text"
              style={{ color: Grey[600] }}
            />
          </Link>
          <a href='https://forms.gle/EjxnTqBK3LCciCUU8' target='_blank' rel='noreferrer'>
            <Button label="Join Waitlist" />
          </a>
        </NavBtnWrapper>

        <MobileMenuBtn>
          <FeatherIcon
            icon="menu"
            size={24}
            onClick={() => setShowMobileNav(!showMobileNav)}
          />
        </MobileMenuBtn>
      </Container>

      <MobileWrapper style={{ display: `${showMobileNav ? "block" : "none"}` }}>
        <MobileContentWrapper>
          <FeatherIcon
            onClick={() => setShowMobileNav(!showMobileNav)}
            icon="x"
            size={32}
          />

          <MobileCTA>
            <Link to="#">
              <Button
                label="Terms & Agreement"
                variant="text"
                style={{ color: Grey[600] }}
              />
            </Link>
            <a href='https://forms.gle/EjxnTqBK3LCciCUU8' target='_blank' rel='noreferrer'>
              <Button label="Join Waitlist" />
            </a>
          </MobileCTA>
        </MobileContentWrapper>
      </MobileWrapper>
    </Nav>
  );
};

export default Navbar;

import styled from "styled-components";
import {
  Primary,
  Secondary,
  Grey,
  Success,
  Error,
  Warning,
  Info,
} from "./../Colors";

const ButtonStyle = styled.button`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  /* float: left; */
  font-size: ${(props) =>
    props.size === "md" ? "14px" : props.size === "sm" ? "12px" : "16px"};
  padding: ${(props) =>
    props.size === "md"
      ? "8px 16px"
      : props.size === "sm"
      ? "6px 12px"
      : "16px 32px"};
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  width: ${(props) => (props.block ? "100%" : "auto")};
  background: ${(props) =>
    props.variant === "outline" || props.variant === "text"
      ? "none"
      : props.state === "disable"
      ? Grey[100]
      : props.color === "secondary"
      ? Secondary[500]
      : props.color === "grey"
      ? Grey[50]
      : props.color === "success"
      ? Success[500]
      : props.color === "error"
      ? Error[500]
      : props.color === "warning"
      ? Warning[500]
      : props.color === "info"
      ? Info[500]
      : Primary[500]};
  color: ${(props) =>
    props.state === "disable"
      ? Grey[300]
      : props.variant === "outline" && props.color === "grey"
      ? Grey[200]
      : props.variant !== "outline" && props.variant !== "text"
      ? props.color === "grey"
        ? Grey[900]
        : "#fff"
      : props.color === "secondary"
      ? Secondary[500]
      : props.color === "grey"
      ? props.mode === "light"
        ? Grey[700]
        : Grey[50]
      : props.color === "success"
      ? Success[500]
      : props.color === "error"
      ? Error[500]
      : props.color === "warning"
      ? Warning[500]
      : props.color === "info"
      ? Info[500]
      : props.variant !== "outline" || props.variant !== "text"
      ? Primary[500]
      : "#fff"};
  border: ${(props) =>
    props.variant === "outline"
      ? `2px solid ${
          props.state === "disable"
            ? Grey[300]
            : props.color === "secondary"
            ? Secondary[500]
            : props.color === "grey"
            ? props.mode === "light"
              ? Grey[700]
              : Grey[200]
            : props.color === "success"
            ? Success[500]
            : props.color === "error"
            ? Error[500]
            : props.color === "warning"
            ? Warning[500]
            : props.color === "info"
            ? Info[500]
            : Primary[500]
        }`
      : "none"};

  &:hover {
    background: ${(props) =>
      (props.state === "disable" && props.variant === "text") ||
      props.variant === "outline"
        ? "none"
        : props.state === "disable"
        ? Grey[100]
        : props.variant === "text"
        ? "none"
        : props.color === "secondary"
        ? Secondary[700]
        : props.color === "success"
        ? Success[700]
        : props.color === "error"
        ? Error[700]
        : props.color === "warning"
        ? Warning[700]
        : props.color === "info"
        ? Info[700]
        : Primary[700]};

    border: ${(props) =>
      props.variant === "outline"
        ? `2px solid ${
            props.state === "disable"
              ? Grey[300]
              : props.color === "secondary"
              ? Secondary[700]
              : props.color === "grey"
              ? Primary[700]
              : props.color === "success"
              ? Success[700]
              : props.color === "error"
              ? Error[700]
              : props.color === "warning"
              ? Warning[700]
              : props.color === "info"
              ? Info[700]
              : Primary[700]
          }`
        : "none"};

    color: ${(props) =>
      props.state === "disable"
        ? Grey[300]
        : props.variant !== "text" && props.variant !== "outline"
        ? "#fff"
        : props.variant === "outline" && props.color === "grey"
        ? Primary[700]
        : props.color === "secondary"
        ? Secondary[700]
        : props.color === "grey"
        ? Grey[500]
        : props.color === "success"
        ? Success[700]
        : props.color === "error"
        ? Error[700]
        : props.color === "warning"
        ? Warning[700]
        : props.color === "info"
        ? Info[700]
        : props.variant !== "outline" || props.variant !== "text"
        ? Primary[700]
        : "#fff"};
  }

  &:disabled {
    opacity: 0.7;
    &:hover {
      background: ${(props) => (props.mode === "light" ? Grey[900] : Grey[50])};
      color: ${(props) => (props.mode === "light" ? Grey[50] : Grey[900])};
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    opacity: 0.7;
  }

  ${(props) => props.style}
`;

export const ButtonGroup = styled.div`
  display: flex;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export default ButtonStyle;

import { Container, SectionWrapper } from '../../../../helpers/GlobalStyle'
import { Primary } from '../../../atoms/Colors'
import Typography from '../../../atoms/Typography/Typography'
import bg from './../../../../assets/images/downloadbg.png'
import ios from './../../../../assets/images/appstore.png'
import android from './../../../../assets/images/google-play.png'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const AppStoreWrapper = styled.div`
    background-color: ${Primary[500]};
    background-image: url(${bg});

    @media (max-width: 375px) {
        img {
            width: 120px;
        }
    }
`

const AppStore = () => {
    return (
        <AppStoreWrapper>
            <SectionWrapper>
                <Container>
                    <Typography
                        variant='h2'
                        weight='semi-bold'
                        align='center'
                        style={{ color: '#fff' }}
                    >
                        Get Habila today!
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 20,
                            marginTop: 20,
                        }}
                    >
                        <Link to='#'>
                            <img src={ios} className='app' loading='lazy' />
                        </Link>
                        <Link to='#'>
                            <img src={android} className='app' loading='lazy' />
                        </Link>
                    </div>
                </Container>
            </SectionWrapper>
        </AppStoreWrapper>
    )
}

export default AppStore

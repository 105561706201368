import styled from "styled-components";

export const JourneyWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin-top: 20px;
  .middle {
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    grid-template-columns: auto;
    .middle {
      margin-top: 0px;
    }
  }
`;

export const JourneyCard = styled.div`
  padding: 20px;
  gap: 20px;
  border-radius: 30px;
  background: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  // align-items: center
  // text-align: center
`;

import styled from "styled-components";
import { Extra, Grey, Primary } from "../../../atoms/Colors";

export const HeroWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 720px;
  overflow: hidden;
  .phone {
    margin-left: -190px;
    margin-top: 40px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 320px;
  }
  @media (max-width: 1024px) {
    .phone {
      margin-left: -160px;
    }
  }
  @media (max-width: 520px) {
    height: 600px;

    .btn {
      width: 100%;
    }
    .phone {
      width: 300px;
      margin-left: -150px;
    }
  }
`;

export const AppCard = styled.div`
  position: relative;
  width: 160px;
  height: 100px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 5px;
  background-color: ${(props) => props.bgcolor};
  p {
    color: ${(props) => props.textColor};
    margin: 0px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 770px;
  margin: auto;
  overflow: hidden;
  overflow-wrap: break-word;
  hyphens: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

import { Container } from '../../../../helpers/GlobalStyle'
import Typography from '../../../atoms/Typography/Typography'
import { AppCard, HeroWrapper, Wrapper } from './HeroStyle'
import Button from '../../../atoms/Button/Button'
import AnimatedText from 'react-animated-text-content'

import Marquee from 'react-fast-marquee'
import bg from './../../../../assets/images/hero-lines.png'
import shadow from './../../../../assets/images/hero-shadow.png'
import phone from './../../../../assets/images/hero-phone.png'
import { Info, Primary, Error } from '../../../atoms/Colors'

const data = [
    {
        icon: '⛱',
        text: 'emergency',
        amt: '₦650,500',
        bgColor: Primary[50],
        textColor: Primary[500],
    },

    {
        icon: '🏠',
        text: 'rent',
        amt: '₦1,213,400',
        bgColor: Info[50],
        textColor: Info[500],
    },
    {
        icon: '📱',
        text: 'New Phone',
        amt: '₦160,000',
        bgColor: Error[50],
        textColor: Error[500],
    },

    {
        icon: '🔐',
        text: '2025',
        amt: '₦10,000,000',
        bgColor: Info[50],
        textColor: Info[500],
    },
    {
        icon: '🚗',
        text: 'first car',
        amt: '₦2,003,400',
        bgColor: Primary[50],
        textColor: Primary[500],
    },

    {
        icon: '✈️',
        text: 'vacation',
        amt: '₦1,093,400',
        bgColor: Info[50],
        textColor: Info[500],
    },
    {
        icon: '📱',
        text: 'New Phone',
        amt: '₦160,000',
        bgColor: Error[50],
        textColor: Error[500],
    },

    {
        icon: '🔐',
        text: '2025',
        amt: '₦10,000,000',
        bgColor: Info[50],
        textColor: Info[500],
    },
    {
        icon: '🚗',
        text: 'first car',
        amt: '₦2,003,400',
        bgColor: Primary[50],
        textColor: Primary[500],
    },
]

const Hero = () => {
    return (
        <HeroWrapper style={{ backgroundImage: `url(${bg})` }}>
            <Container>
                <Wrapper>
                    <Typography
                        variant='h1'
                        weight='semi-bold'
                        align='center'
                        style={{ textTransform: 'none' }}
                    >
                        <AnimatedText
                            type='words' // animate words or chars
                            animation={{
                                x: '200px',
                                y: '-20px',
                                scale: 1.5,
                                ease: 'ease-in-out',
                            }}
                            animationType='blocks'
                            interval={0.06}
                            duration={0.5}
                            tag='span'
                            className='animated-paragraph'
                            includeWhiteSpaces
                            threshold={0.1}
                            // rootMargin="20%"
                        >
                            Your money’s best friend
                        </AnimatedText>
                    </Typography>

                    <Typography variant='p1' align='center'>
                    Whether you’re saving, investing, we’ve got an easy way for you to build wealth using our smart automated tools
                    </Typography>
                    <a href='https://forms.gle/EjxnTqBK3LCciCUU8' target='_blank' rel='noreferrer'>
                        <Button
                            label='Join Waitlist'
                            showIcon='right'
                            className='btn'
                            style={{ margin: 'auto' }}
                        />
                    </a>
                </Wrapper>
            </Container>

            <Marquee style={{ position: 'absolute', bottom: 20 }}>
                {data.map((val, key) => (
                    <AppCard key={key} bgcolor={val.bgColor} textColor={val.textColor}>
                        {/* <img src={val.icon} width={28} /> */}
                        <Typography variant='h5'> {val.icon}</Typography>
                        <div>
                            <Typography variant='p2' style={{ textTransform: 'capitalize' }}>
                                {val.text}
                            </Typography>
                            <Typography variant='p3'>{val.amt}</Typography>
                        </div>
                    </AppCard>
                ))}
            </Marquee>

            <img src={shadow} style={{ position: 'absolute', bottom: 0, zIndex: -1 }} loading='lazy' />

            <img src={phone} className='phone' loading='lazy' />
        </HeroWrapper>
    )
}

export default Hero
